const worldMenu = {
  Default: {
    story: 'story_section',
    bios: 'bios_section',
    map: 'map_section',
    language: 'language_section',
  },
  ENG: {
    story: 'Story',
    bios: 'Bios',
    map: 'Map',
    language: 'Language',
  },
  ESP: {
    story: 'Historia',
    bios: 'Bios',
    map: 'Mapa',
    language: 'Lenguaje',
  },
  ADN: {
    story: 'Olanuin',
    bios: 'Kuraxi',
    map: 'Protlaz',
    language: 'Kikirur',
  },
}

export {worldMenu}