import React from 'react'

// Utils
import { getFont } from '../../../utils/utils'

// Components
import WordCard from '../../atoms/WordCard'

// Styles
import { sectionTitle, sectionPanel, sectionWords } from './styles.module.scss'

// Models
import { WordSectionModel } from '../../../models'
import { ValidLanguage } from '../../../types'

interface Props {
  section: WordSectionModel
  language: ValidLanguage
}

const WordSection: React.FC<Props> = ({ section, language, ...attr }) => {
  return (
    <div className={sectionPanel}>
      <div className={`${sectionTitle} ${getFont('font-patua')}`}>
        {section.name[language]}
      </div>
      <div className={sectionWords}>
        {section.words.map((word) => (
          <WordCard
            word={word}
            language={language}
            key={`world_card_${word.id}`}
          />
        ))}
      </div>
    </div>
  )
}

export default WordSection
