import React, { useContext } from 'react'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Context
import LocalizationContext from '../../../context/Localization/Localization.context'

// Config
import { worldMenu } from '../../../config/localization/worldMenu'

// Types
import { WorldLocations } from '../../../types'

// Utils
import { getFont } from '../../../utils/utils'

// Components
import WorldMenu from '../../organisms/WorldMenu'

// Styles
import {
  arrowStyle,
  titleStyle,
  blueWrapper,
  yellowWrapper,
  titleIcon,
  titleContainer,
  lowerContainer,
  contentContainer,
} from './styles.module.scss'

interface Props {
  location: WorldLocations
  title?: string
  icon?: string
  children: React.ReactNode
}
const WorldTemplate: React.FC<Props> = ({
  children,
  location,
  title,
  icon,
}) => {
  const { language } = useContext(LocalizationContext)

  return (
    <>
      <div className={`block-wrapper ${yellowWrapper}`}>
        <div className={`container ${titleContainer}`}>
          <h2 className={`${titleStyle} ${getFont('font-patua')}`}>
            <FontAwesomeIcon
              icon={faArrowLeft}
              className={arrowStyle}
              onClick={() => window.history.back()}
            />
            {icon ? <img src={icon} className={titleIcon} /> : null}
            {title ? title : worldMenu[language][location]}
          </h2>
        </div>
      </div>
      <div className={`block-wrapper ${blueWrapper}`}>
        <div className={`container ${lowerContainer}`}>
          <WorldMenu localization={worldMenu[language]} location={location} />
          <div className={contentContainer}>{children}</div>
        </div>
      </div>
    </>
  )
}

export default WorldTemplate
