import React, { useContext } from 'react'

// GraphQL
import { getGraphWordSections } from '../../../graphQL'

// Context
import LocalizationContext from '../../../context/Localization/Localization.context'

// Components
import WordSection from '../../molecules/WordSection'

// Styles
import { languageWrapper } from './styles.module.scss'

const LanguagePanel: React.FC = () => {
  const sections = getGraphWordSections()
  const { language } = useContext(LocalizationContext)

  return (
    <>
      <div className={languageWrapper}>
        {sections.map((sec) => {
          return (
            <WordSection
              key={`word_section_${sec.id}`}
              section={sec}
              language={language}
            />
          )
        })}
      </div>
    </>
  )
}

export default LanguagePanel
