import React from 'react'
import { navigate } from 'gatsby'

//Utils
import { getFont } from '../../../utils/utils'

// Types
import { WorldLocations } from '../../../types'

// Styles
import {
  menuContainer,
  buttonIcon,
  buttonCommon,
  buttonActive,
  buttonInactive,
  buttonContainer,
} from './styles.module.scss'

interface Props {
  localization: {
    story: string
    bios: string
    map: string
    language: string
  }
  location: WorldLocations
}

const WorldMenu: React.FC<Props> = ({ localization, location }) => {
  return (
    <>
      <div className={menuContainer}>
        <div className={buttonContainer}>
          <div
            className={`${buttonCommon} ${
              location === 'bios' ? buttonActive : buttonInactive
            }`}
            onClick={() => {
              if (!window.location.href.endsWith('bios'))
                navigate('/world/bios')
            }}
          >
            <img
              src="https://www.dilancovak.com/.img/system/bios.svg"
              className={buttonIcon}
            />
            <div className={getFont('font-lato')}>{localization.bios}</div>
          </div>
          <div
            className={`${buttonCommon} ${
              location === 'story' ? buttonActive : buttonInactive
            }`}
            onClick={() => {
              if (!window.location.href.endsWith('story'))
                navigate('/world/story')
            }}
          >
            <img
              src="https://www.dilancovak.com/.img/system/story.svg"
              className={buttonIcon}
            />
            <div className={getFont('font-lato')}>{localization.story}</div>
          </div>
          <div
            className={`${buttonCommon} ${
              location === 'map' ? buttonActive : buttonInactive
            }`}
            onClick={() => {
              if (!window.location.href.endsWith('map')) navigate('/world/map')
            }}
          >
            <img
              src="https://www.dilancovak.com/.img/system/map.svg"
              className={buttonIcon}
            />
            <div className={getFont('font-lato')}>{localization.map}</div>
          </div>
          <div
            className={`${buttonCommon} ${
              location === 'language' ? buttonActive : buttonInactive
            }`}
            onClick={() => {
              if (!window.location.href.endsWith('language'))
                navigate('/world/language')
            }}
          >
            <img
              src="https://www.dilancovak.com/.img/system/language.svg"
              className={buttonIcon}
            />
            <div className={getFont('font-lato')}>{localization.language}</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default WorldMenu
