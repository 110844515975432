import React, { useState } from 'react'
import * as Icon from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Models
import { ValidLanguage } from '../../../types'
import { WordModel } from '../../../models/Word.type'

// Styles
import {
  card,
  cardImg,
  cardBack,
  cardInner,
  cardFront,
  cardInnerFlipped,
} from './styles.module.scss'
import { capitalizeFirst, getFont, romanizeAdn } from '../../../utils/utils'

interface Props {
  word: WordModel
  language: ValidLanguage
}

const WordCard: React.FC<Props> = ({ word, language }) => {
  const [clicked, setClicked] = useState(false)
  const [hover, setHover] = useState(false)

  return (
    <div className={card}>
      <div
        className={`${cardInner} ${clicked ? cardInnerFlipped : ''}`}
        onClick={() => {
          setClicked(!clicked)
        }}
        onMouseOver={() => setHover(true)}
        onMouseOut={() => {
          setHover(false)
        }}
      >
        <div className={cardFront}>
          <FontAwesomeIcon className={cardImg} icon={Icon[word.icon]} />
          <div className={`${hover ? 'font-lato' : 'font-adina'}`}>
            {hover ? capitalizeFirst(romanizeAdn(word.ADN)) : word.ADN}
          </div>
        </div>
        <div className={cardBack}>
          <FontAwesomeIcon className={cardImg} icon={Icon[word.icon]} />
          <div className={getFont('font-lato')}>{word[language]}</div>
        </div>
      </div>
    </div>
  )
}

export default WordCard
