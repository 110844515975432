import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'

// Components
import PageTemplate from '../../../components/templates/PageTemplate'
import LanguagePanel from '../../../components/organisms/LanguagePanel'
import WorldTemplate from '../../../components/templates/WorldTemplate'

// Context
import LocalizationContext from '../../../context/Localization/Localization.context'

const LanguagePage = () => {
  const { language } = useContext(LocalizationContext)
  return (
    <PageTemplate>
      <Helmet>
        <title>
          {language === 'ESP' ? 'Lenguaje ' : 'Language '}- Dilan
          {language === 'ESP'
            ? ' las crónicas de Covak'
            : ' The chronicles of Covak'}
        </title>
        <meta
          name="description"
          content={
            language === 'ESP'
              ? 'Aprende Adina, el poderoso y magico lenguaje que los personajes de Dilan usan para comunicarse.'
              : 'Learn Adina, the powerful and magical languaje that the characters of Dilan use to communicate.'
          }
        />
        <meta
          name="keywords"
          content={
            language == 'ESP'
              ? 'Dilan Covak, webcomic, comic, comic gratis, fantasia, magia, espadas, peleas, Dilan, blanco y negro, mundo, lenguaje, adina'
              : 'Dilan Covak, webcomic, comic, free comic, fantasy, magic, swords, fights, Dilan, black and white, world, bios, language, adina'
          }
        />
        <meta name="author" content="Daniel Penagos" />
        <meta name="copyright" content="Daniel Penagos" />
      </Helmet>
      <WorldTemplate location="language">
        <LanguagePanel />
      </WorldTemplate>
    </PageTemplate>
  )
}

export default LanguagePage
